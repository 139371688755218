<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <b-row>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group :label="$t('code')">
                        <div class="label-as-input">{{ form.course_code }} - {{ form.course_name }} </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group :label="$t('can_take_same_semester')">
                        <div class="label-as-input">{{ form.can_take_same_semester == '0' ? $t('no') : $t('yes') }}</div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="12" v-for="(item, key) in form.department_codes" :key="`department-${key}`">
                    <b-form-group :label="$t('department_codes')">
                        <div class="label-as-input">{{ item }}</div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="12" v-for="(data, key) in form.prerequisites_text" :key="`prerequisites-${key}`">
                    <b-form-group :label="$t('prerequisites')">
                        <div class="label-as-input">{{ data }}</div>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
//Component
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
//Other
//Service
import CoursePrerequisitesService from "@/services/CoursePrerequisitesService";

export default {
    components: {
        CourseAutoComplete,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    data() {
        return {
            formLoading: false,
            prerequisitess: null,
            data: null,
            id: 0,
            form: {
                department_codes: null,
            },
            courses: [],
            course: null,
        }
    },
    methods: {
        get(id) {
            this.formLoading = true;
            CoursePrerequisitesService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.formLoading = false;

                })
                .catch((error) => {
                    this.showErrors(error, this.$refs.storeForm)
                })
        },
    }
}
</script>
